import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { Box, Grid, Paper } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import { useForm, FormProvider } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';

import logo from '../../assets/images/ellasLogo.png';
import './LoginPage.css';
import { useLoginMutation } from '../../store';
import { useNavigate } from 'react-router-dom';
import FormInputText from '../FormInputText/FormInputText';

const REQUIRED_MESSAGE = 'Completa este campo';

function LoginPage(props) {
  const [errors, setErrors] = useState({});
  const [login, { isLoading, isError, isSuccess }] = useLoginMutation();
  const navigate = useNavigate();

  const loginSchema = z.object({
    email: z.string({ required_error: REQUIRED_MESSAGE }).email('Email inválido'),
    password: z.string({ required_error: REQUIRED_MESSAGE })
  });

  const methods = useForm({ resolver: zodResolver(loginSchema) });
  const { handleSubmit } = methods;

  useEffect(() => {
    if (isSuccess) {
      navigate('/');
    }
    if (isError) {
      setErrors({ form: 'Su usuario o contraseña son incorrectos, por favor pruebe de nuevo' });
    }
  }, [isLoading]);

  const onSubmit = (data) => {
    login({ username: data.email, password: data.password });
  };

  return (
    <React.Fragment>
      {props.featureToggle && (
        <div>
          <Paper elevation={10} className="loginContainer">
            <Grid
              container
              align="center"
              justify="center"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={12}>
                <Avatar alt="Ellas icon" src={logo} style={{ alignSelf: 'center' }} sx={{ width: 65, height: 65, marginTop: 2 }}></Avatar>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h4" align={'center'}>
                  Iniciar sesión
                </Typography>
              </Grid>
              <Grid item xs={12} className="formContainer">
                <FormProvider {...methods} >
                  <FormInputText name="email" label="Correo electrónico" 
                    required className="formInput" inputProps={{ 'data-testid': 'email-field' }} />
                  <FormInputText name="password" label="Contraseña" required type="password"  
                    className="formInput" inputProps={{ 'data-testid': 'password-field' }} />
                  <Box display="flex" justifyContent="center">
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      onClick={handleSubmit(onSubmit)}
                      className="formButton"
                    >
                      Iniciar sesión
                    </Button>
                  </Box>
                </FormProvider>
                {errors.form && <Typography variant="body2" color="error" className="formError"
                  sx={{ marginBottom: 2 }}>
                  {errors.form}
                </Typography>}
              </Grid>
            </Grid>
          </Paper>
        </div>
      )}
    </React.Fragment>
  );
}

export default LoginPage;
