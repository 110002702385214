import serverURL from '../../api/base';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const authApi = createApi({
  reducerPath: 'auth',
  baseQuery: fetchBaseQuery({
    baseUrl: serverURL
  }),
  endpoints(builder) {
    return {
      login: builder.mutation({
        query: (user) => {
          return {
            url: '/login',
            method: 'POST',
            body: {
              username: user.username,
              password: user.password
            },
            credentials: 'include'
          };
        }
      }),
      logout: builder.mutation({
        query: () => {
          return {
            url: '/logout',
            method: 'POST',
            credentials: 'include'
          };
        }
      })
    };
  }
});

export const { useLoginMutation, useLogoutMutation } = authApi;
export { authApi };
