import React from 'react';
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

import './RootPage.css';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';

function RootPage() {
  const { loginPageFeatureToggle, signInPageFeatureToggle } = useSelector((state) => state.featureToggles);

  return (
    <div id="body">
      <Navbar loginPageFeatureToggle={loginPageFeatureToggle} signInPageFeatureToggle={signInPageFeatureToggle} />
      <div id="body-content">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
}

export default RootPage;
