import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { TextField } from '@mui/material';

const FormInputText = ({ name, label, ...otherProps }) => {
  const { control, formState: { errors } } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <TextField
          helperText={errors[name] ? errors[name].message : null}
          error={!!errors[name]}
          onChange={field.onChange}
          value={field.value || ''}
          fullWidth
          label={label}
          variant={'filled'}
          {...otherProps}
        />
      )}
    />
  );
};

export default FormInputText;
