import React, { useState, useEffect } from 'react';

import { Box, List, ListItemText, ListItemButton, Divider, Collapse } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import BrandsApi from '../../api/brands/brands';
import FilterForm from './FilterForm/FilterForm';

function ItemsFilter({ setFilterCategory }) {
  const [brands, setBrands] = useState([]);
  const [open, setOpen] = React.useState(true);

  useEffect(() => {
    const search = async () => {
      const itemsSearched = await BrandsApi.getAllBrands();
      setBrands(itemsSearched);
    };

    search();
  }, []);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Box sx={{ width: '100%', maxWidth: 300, bgcolor: 'background.paper' }}>
      <nav>
        <List>
          <Divider />
          <ListItemButton disablePadding onClick={handleClick}>
            <ListItemText primary="Marca" />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={open} timeout="auto">
            <List component="div" disablePadding>
              <FilterForm name={'brand'} filters={brands} setFilter={setFilterCategory} />
            </List>
          </Collapse>
          <Divider />
        </List>
      </nav>
    </Box>
  );
}

export default ItemsFilter;
