import React from 'react';
import Carousel from 'react-material-ui-carousel';
import Typography from '@mui/material/Typography';
import CarouselElement from './CarouselElement/CarouselElement';

function ItemCarousel(props) {
  return (
    <div>
      <Typography gutterBottom variant="h5" component="div" sx={{ fontWeight: 'bold' }}>
        {props.title}
      </Typography>
      <Carousel>
        {
          props.items.map( (item, i) => <CarouselElement key={i} item={item} /> )
        }
      </Carousel>
    </div>
  );
}

export default ItemCarousel;
