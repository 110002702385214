import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { Box, Grid, Paper, Avatar, Button } from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { useSignInMutation } from '../../store';
import { useNavigate } from 'react-router-dom';
import FormInputText from '../FormInputText/FormInputText';

import styles from './SignInPage.module.css';
import logo from '../../assets/images/ellasLogo.png';

const REQUIRED_MESSAGE = 'Completa este campo';

function SignInPage({ featureToggle }) {
  const [errors, setErrors] = useState({});
  const [signIn, { isLoading, isError, error, isSuccess }] = useSignInMutation();
  const navigate = useNavigate();

  const signInSchema = z.object({
    username: z.string({ required_error: REQUIRED_MESSAGE }),
    email: z.string({ required_error: REQUIRED_MESSAGE }).email('Email inválido'),
    password: z.string({ required_error: REQUIRED_MESSAGE })
      .refine((value) => /.*[a-z].*/.test(value), { message: 'Tiene que contener al menos una letra minúscula' })
      .refine((value) => /.*[A-Z].*/.test(value), { message: 'Tiene que contener al menos una letra mayúscula' })
      .refine((value) => /.*[\d].*/.test(value), { message: 'Tiene que contener al menos una número' })
  });

  const methods = useForm({ resolver: zodResolver(signInSchema) });
  const { handleSubmit } = methods;

  useEffect(() => {
    if (isSuccess) {
      navigate('/');
    }
    if (isError) {
      setErrors({ form: error.data });
    }
  }, [isLoading]);

  const onSubmit = (data) => {
    signIn({ accountName: data.username, emailAddress: data.email, password: data.password });
  };

  return (
    <React.Fragment>
      {featureToggle && (
        <div>
          <Paper elevation={10} className={styles.signInContainer}>
            <Grid
              container
              align="center"
              justify="center"
              justifyContent="center"
              spacing={2}
            >
              <Grid item xs={12}>
                <Avatar alt="Ellas icon" src={logo} style={{ alignSelf: 'center' }} sx={{ width: 65, height: 65, marginTop: 2 }}></Avatar>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h4" align={'center'}>
                  Crear cuenta
                </Typography>
              </Grid>
              <Grid item xs={12} className={styles.formContainer}>
                <FormProvider {...methods} >
                  <FormInputText name="username" label="Nombre" 
                    required className={styles.formInput} inputProps={{ 'data-testid': 'username-field' }} />
                  <FormInputText name="email" label="Correo electrónico" 
                    required className={styles.formInput} inputProps={{ 'data-testid': 'email-field' }} />
                  <FormInputText name="password" label="Contraseña" required type="password"  
                    className={styles.formInput} inputProps={{ 'data-testid': 'password-field' }} />
                  <Box display="flex" justifyContent="center">
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      onClick={handleSubmit(onSubmit)}
                      className={styles.formButton}
                    >
                      Crear cuenta
                    </Button>
                  </Box>
                </FormProvider>
                {errors.form && <Typography variant="body2" color="error" className={styles.formError}>
                  {errors.form}
                </Typography>}
              </Grid>
            </Grid>
          </Paper>
        </div>
      )}
    </React.Fragment>
  );
}

export default SignInPage;
