import axios from '../axiosConfig';
import serverURL from '../base';

const ImagesApi = {
  getImagesByItemId: async (id) => {
    const response = await axios.get(`${serverURL}/images/${id}`);
    return response.data;
  },
};

export default ImagesApi;
