import React, { useEffect, useState } from 'react';
import ImagesApi from '../../api/images/images';
import { useParams } from 'react-router-dom';
import { Grid, ImageList, ImageListItem, ToggleButton, ToggleButtonGroup } from '@mui/material';
import ItemsApi from '../../api/items/items';
import Typography from '@mui/material/Typography';
import Price from '../Price/Price';
import SizesApi from '../../api/sizes/sizes';

function SingleItemPage({ featureToggle }) {
  const { itemId } = useParams();
  const [images, setImages] = useState([]);
  const [itemInfo, setItemInfo] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [selectedSize, setSelectedSize] = useState(null);

  useEffect(() => {
    const search = async () => {
      const imagesSearched = await ImagesApi.getImagesByItemId(itemId);
      setImages(imagesSearched);

      const item = await ItemsApi.getItemById(itemId);
      setItemInfo(item);

      const sizesSearched = await SizesApi.getSizesByItemId(itemId);
      setSizes(sizesSearched);
    };

    search();
  }, []);

  const handleSelectedSize = (event, newSelectedSize) => {
    setSelectedSize(newSelectedSize);
  };

  return (
    <React.Fragment>
      {featureToggle && (
        <React.Fragment>
          <div className="page-container">
            <Grid container spacing={4} alignItems="flex-start"
              justifyContent="center" direction={{ xs: 'column', md: 'row' }} >
              <Grid item key="itemImages" xs>
                <ImageList cols={2}>
                  {images.map((itemImage, index) => (
                    <ImageListItem key={index}>
                      <img
                        src={itemImage.link}
                        alt={index}
                        loading="lazy"
                      />
                    </ImageListItem>
                  ))}
                </ImageList>
              </Grid>
              <Grid item key="itemInfo" xs={4} >
                <Typography gutterBottom variant="h4"
                  sx={{ fontFamily: 'Inter', fontWeight: 'bold' }}>
                  {itemInfo.name}
                </Typography>
                <Price price={itemInfo.price} discount={itemInfo.discount}
                  priceCss={{ fontWeight: 'bold' }}
                  discountCss={{ fontWeight: 'bold' }} />
                <br/>
                <Typography gutterBottom variant="body1">
                                    Modelo: {itemInfo.model}
                </Typography>
                <Typography gutterBottom variant="h6">
                                    Tallas disponibles
                </Typography>
                <ToggleButtonGroup
                  value={selectedSize}
                  exclusive
                  onChange={handleSelectedSize}
                  aria-label="selected size"
                  sx={{ display: 'grid', gridTemplateColumns: 'auto auto auto auto', gridGap: '20px' }}
                >
                  {sizes.map((itemSize) => (
                    <ToggleButton
                      value={itemSize.name}
                      aria-label={itemSize.name}
                      key={itemSize.name}
                      disabled={itemSize.quantity === 0}
                      sx={{
                        boxShadow: '1px 1px 10px 1px #d4d4d4',
                      }}
                    >
                      {itemSize.name}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
                <br/>
                <Typography gutterBottom variant="body1">
                  {itemInfo.description}
                </Typography>
              </Grid>
            </Grid>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default SingleItemPage;
