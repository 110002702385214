import React from 'react';
import { Card, CardActionArea, CardContent, CardMedia, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

function CarouselElement(props) {
  const getPriceWithDiscount = () => {
    return (props.item.price * (1 - props.item.discount/100)).toFixed(2);
  };

  return (
    <Card className="carousel-element" sx={{ borderRadius: 2 }}>
      <CardActionArea component={Link} to={'/items/' + props.item.variantId}>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs>
            <CardContent>
              <Typography gutterBottom variant="h3" component="div" className="typography"
                sx={{ fontWeight: 'bold', typography: { sm: 'h3', xs: 'h5' } }}>
                { props.item.name }
              </Typography>
              <Grid container spacing={1} alignItems="center" justifyContent="flex-start">
                <Grid item key="price">
                  <Typography variant="body2" className="typography"
                    sx={{ textDecoration: 'line-through', typography: { sm: 'body2', xs: 'subtitle1' } }}>
                    { props.item.price }€
                  </Typography>
                </Grid>
                <Grid item key="discount">
                  <Typography variant="body2" color="darkred" className="typography"
                    sx={{ typography: { sm: 'body2', xs: 'subtitle1' } }}>
                    { getPriceWithDiscount() }€
                  </Typography>
                </Grid>
              </Grid>
              <Typography variant="h5" color="darkred" className="typography" sx={{ typography: { sm: 'h4', xs: 'h6' } }}>
                { props.item.discount }%
              </Typography>
            </CardContent>
          </Grid>
          <Grid item xs={8}>
            <CardMedia
              component="img"
              height="432"
              image={ props.item.mainImage }
              alt={ props.item.name }
            />
          </Grid>
        </Grid>
      </CardActionArea>
    </Card>
  );
}

export default CarouselElement;
