import React from 'react';
import { Grid, Typography } from '@mui/material';

import ItemCard from './ItemCard/ItemCard';
import styles from './ItemGrid.module.css';

function ItemGrid(props) {
  return (
    <div className={styles.itemsList}>
      <Typography gutterBottom variant='h5' component='div' sx={{ fontFamily: 'Inter', fontWeight: 'bold' }}>
        {props.title}
      </Typography>
      <Grid container spacing={4} alignItems="center" justifyContent="center" >
        {props.items.map((item, index) => (
          <Grid item key={index}>
            <ItemCard item={item} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default ItemGrid;
