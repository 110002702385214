import React from 'react';
import './Footer.css';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { GrInstagram, GrFacebook, GrGithub } from 'react-icons/gr';

import logo from '../../assets/images/ellasLogo.png';

const socialMedias = [
  { alt: 'Instagram Ellas', icon: <GrInstagram className='icon'/>,
    href: 'https://www.instagram.com/ellas_boutique_official/?hl=es' },
  { alt: 'Facebook Ellas', icon: <GrFacebook className='icon'/>,
    href: 'https://es-la.facebook.com/EllasBoutiqueofficial/' },
  { alt: 'Github Ellas', icon: <GrGithub className='icon'/>, href: 'https://github.com/garrido18/ellas-web' }];

function Footer() {
  return (
    <AppBar position='static' color='primary' enableColorOnDark >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            align="left"
            variant="p"
            noWrap
            component="a"
            href="/"
            className="nameEllas"
            sx={{
              mr: 2,
              fontSize: '15px',
              letterSpacing: '.0rem',
              color: 'Grey',
              textDecoration: 'none',
              alignItems: 'center',
              flexGrow: 1,
            }}
          >
                        Cookies
          </Typography>

          <Box sx={{ display: 'flex', flexGrow: 1 }}>
            <img
              id="logoEllas"
              src={logo}
              className="logoEllas"
              alt="Ellas logo"
            />
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            {socialMedias.map((socialMedia) => (
              <IconButton key={socialMedia.alt} aria-label={socialMedia.alt} component="a" href={socialMedia.href}>
                {socialMedia.icon}
              </IconButton>
            ))}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Footer;
