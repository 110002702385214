import axios from '../axiosConfig';
import serverURL from '../base';

const BrandsApi = {
  getAllBrands: async () => {
    const response = await axios.get(`${serverURL}/brands`);
    return response.data;
  },
};

export default BrandsApi;
