import serverURL from '../../api/base';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const signInApi = createApi({
  reducerPath: 'register',
  baseQuery: fetchBaseQuery({
    baseUrl: serverURL
  }),
  endpoints(builder) {
    return {
      signIn: builder.mutation({
        query: (user) => {
          return {
            url: '/register',
            method: 'POST',
            body: {
              accountName: user.accountName,
              emailAddress: user.emailAddress,
              password: user.password
            },
            credentials: 'include'
          };
        }
      })
    };
  }
});

export const { useSignInMutation } = signInApi;
export { signInApi };
