import axios from '../axiosConfig';
import serverURL from '../base';

const ItemsApi = {
  getAllItems: async (urlParams) => {
    const response = await axios.get(`${serverURL}/items`, { params: urlParams });
    return response.data;
  },

  getDiscountedItems: async (urlParams) => {
    const response = await axios.get(`${serverURL}/items/discounted`, { params: urlParams });
    return response.data;
  },

  getItemById: async (itemId) => {
    const response = await axios.get(`${serverURL}/items/${itemId}`);
    return response.data;
  },
};

export default ItemsApi;
