import React, { useEffect, useState } from 'react';

import ItemsApi from '../../api/items/items';
import ItemGrid from '../ItemGrid/ItemGrid';
import Typography from '@mui/material/Typography';
import styles from './ProductsPage.module.css';
import ItemsFilter from '../ItemsFilter/ItemsFilter';
import InfiniteScroll from 'react-infinite-scroll-component';

function ProductsPage(props) {
  const [items, setItems] = useState([]);
  const [filters, setFilters] = useState({});
  const [hasMore, setHasMore] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [filterQuery, setFilterQuery] = useState('');
  const title = 'Nuestros productos:';

  useEffect(() => {
    const search = async (searchParameter) => {
      const itemsSearched = await ItemsApi.getAllItems(searchParameter);

      setItems(itemsSearched.items);
      setHasMore(itemsSearched.currentPage < itemsSearched.totalPages);
      setCurrentPage(itemsSearched.currentPage);
    };

    const getFilterSearchParameter = () => {
      let newFilterQuery = '';
      for (const filterCategory in filters) {
        for (const filter in filters[filterCategory]) {
          if (filters[filterCategory][filter]) {
            newFilterQuery += `${filterCategory}:${filter},`;
          }
        }
      }
      newFilterQuery = newFilterQuery.slice(0, -1);

      setFilterQuery(newFilterQuery.slice(0, -1));
      return !newFilterQuery ? {} : { search: newFilterQuery };
    };

    search(getFilterSearchParameter());
  }, [filters]);

  const setFilter = (filterCategory, modifiedFilters) => {
    setFilters({
      ...filters,
      [filterCategory]: modifiedFilters
    });
  };

  const fetchNextPage = async () => {
    let parameters = {
      page: currentPage + 1
    };

    parameters = filterQuery ? { ...parameters, search: filterQuery } : parameters;

    const itemsSearched = await ItemsApi.getAllItems(parameters);

    setItems([...items, ...itemsSearched.items]);
    setCurrentPage(itemsSearched.currentPage);
    setHasMore(itemsSearched.currentPage < itemsSearched.totalPages);
  };

  return (
    <React.Fragment>
      {props.featureToggle && (
        <div className="page-container items-page">
          <Typography gutterBottom variant="h5" component="div" sx={{ fontWeight: 'bold' }}>
            {title}
          </Typography>
          <div className={styles.productsContainer}>
            <ItemsFilter setFilterCategory={setFilter} />
            <div className={styles.itemsList}>
              <InfiniteScroll
                dataLength={items.length}
                next={fetchNextPage}
                hasMore={hasMore}
              >
                <ItemGrid items={items} />
              </InfiniteScroll>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default ProductsPage;
