import React, { useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { useScrollTrigger, Slide } from '@mui/material';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useLogoutMutation } from '../../store';
import { useNavigate } from 'react-router-dom';

import logo from '../../assets/images/ellasLogo.png';
import './Navbar.css';

const pages = [{ name: 'Productos', href: '/items' }, { name: 'Pricing', href: '/' }, { name: 'Blog', href: '/' }];
const settings = ['Profile', 'Account', 'Dashboard'];

function HideOnScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

function Navbar({ loginPageFeatureToggle, signInPageFeatureToggle, ...props }) {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [logout, { isLoading, isSuccess }] = useLogoutMutation();
  const navigate = useNavigate();
  const isLoggedIn = Cookies.get('IsLoggedIn');

  useEffect(() => {
    if (isSuccess) {
      navigate('/');
    }
  }, [isLoading]);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    setAnchorElUser(null);
    logout();
  };

  const brand = (sxProps) => {
    return (
      <Box sx={ sxProps }>
        <img
          id="logoEllas"
          src={logo}
          className="logoEllas"
          alt="Ellas logo"
        />
        <Typography
          align="center"
          variant="h6"
          noWrap
          component={Link}
          to="/"
          className="nameEllas"
          sx={{
            mr: 2,
            fontWeight: 800,
            fontSize: '25px',
            letterSpacing: '.0rem',
            color: 'black',
            textDecoration: 'none',
            alignItems: 'center',
            paddingLeft: '8px',
          }}
        >
                    Ellas
        </Typography>
      </Box>
    );
  };

  const renderLogin = () => {
    const userMenu = (<Box sx={{ flexGrow: 0 }}>
      <Tooltip title="Open settings">
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
          <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: '45px' }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {settings.map((setting) => (
          <MenuItem key={setting} onClick={handleCloseUserMenu}>
            <Typography textAlign="center">{setting}</Typography>
          </MenuItem>
        ))}
        <MenuItem key={'logout'} onClick={handleLogout}>
          <Typography textAlign="center">Cerrar sesión</Typography>
        </MenuItem>
      </Menu>
    </Box>);
    const authMenu = (<Box sx={{ justifyContent: 'flex-end', flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
      {loginPageFeatureToggle && (<Button
        key={'logInButton'}
        onClick={handleCloseNavMenu}
        component={Link}
        to={'/logIn'}
        sx={{
          fontSize: '17px',
          color: 'black',
          textDecoration: 'none',
          display: 'block',
          margin: '10px',
          textTransform: 'none',
        }}
      >
      Iniciar Sesión
      </Button>)}
      {signInPageFeatureToggle && (<Button
        key={'signInButton'}
        onClick={handleCloseNavMenu}
        component={Link}
        to={'/signIn'}
        sx={{
          fontSize: '17px',
          color: 'black',
          textDecoration: 'none',
          display: 'block',
          margin: '10px',
          textTransform: 'none',
        }}
      >
      Crear Cuenta
      </Button>)}
    </Box>);

    if (!loginPageFeatureToggle) {
      return userMenu; 
    }
    
    return isLoggedIn ? userMenu : authMenu;
  };

  return (
    <React.Fragment>
      <HideOnScroll {...props}>
        <AppBar color='secondary' enableColorOnDark>
          <Container maxWidth="xl">
            <Toolbar disableGutters>
              {brand({ display: { xs: 'none', md: 'flex' } })}

              <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="primary"
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: 'block', md: 'none' },
                  }}
                >
                  {pages.map((page) => (
                    <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                      <Typography sx={{
                        color: 'black',
                        textDecoration: 'none',
                      }} textAlign="center" component={Link} to={page.href}>{page.name}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>

              { brand({ display: { xs: 'flex', md: 'none' }, flexGrow: 1 }) }

              <Box sx={{ justifyContent: 'flex-start', flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                {pages.map((page) => (
                  <Button
                    key={page.name}
                    onClick={handleCloseNavMenu}
                    component={Link}
                    to={page.href}
                    sx={{
                      fontSize: '17px',
                      color: 'black',
                      textDecoration: 'none',
                      display: 'block',
                      margin: '10px',
                      textTransform: 'none',
                    }}
                  >
                    {page.name}
                  </Button>
                ))}
              </Box>
              {renderLogin()}
            </Toolbar>
          </Container>
        </AppBar>
      </HideOnScroll>
      <Toolbar />
    </React.Fragment>
  );
}

export default Navbar;
