
const allToggles = {
  developmentFeatureToggles: {
    homePageFeatureToggle: true,
    productsPageFeatureToggle: true,
    singleItemPageFeatureToggle: true,
    loginPageFeatureToggle: true,
    signInPageFeatureToggle: true,
  },

  testFeatureToggles: {
    homePageFeatureToggle: true,
    productsPageFeatureToggle: true,
    singleItemPageFeatureToggle: true,
    loginPageFeatureToggle: true,
    signInPageFeatureToggle: false,
  },

  prodFeatureToggles: {
    homePageFeatureToggle: true,
    productsPageFeatureToggle: true,
    singleItemPageFeatureToggle: false,
    loginPageFeatureToggle: false,
    signInPageFeatureToggle: true,
  },
};

let featureToggles = allToggles.developmentFeatureToggles;

if (process.env.ENV) {
  featureToggles = process.env.ENV === 'test'? allToggles.testFeatureToggles : allToggles.prodFeatureToggles;
}

export default featureToggles;
