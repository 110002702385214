import React from 'react';
import Typography from '@mui/material/Typography';

function NotFound() {
  return (
    <div style={{ paddingTop: 100 }}>
      <Typography gutterBottom variant="h1" component="div"
        className="typography" align="center" sx={{ fontWeight: 'bold' }}>
                Oops!
      </Typography>
      <Typography gutterBottom variant="h4" component="div" className="typography" align="center">
                Lo siento, un error inesperado ha ocurrido.
      </Typography>
    </div>
  );
}

export default NotFound;
