import React, { useState, useEffect } from 'react';

import ItemsApi from '../../api/items/items';
import ItemGrid from '../ItemGrid/ItemGrid';
import ItemCarousel from './ItemCarousel/ItemCarousel';

function Home(props) {
  const [recentItems, setRecentItems] = useState([]);
  const [discountedItems, setDiscountedItems] = useState([]);

  useEffect(() => {
    const search = async () => {
      const recentItemsSearched = await ItemsApi.getAllItems({ size: 4, sortBy: 'createAt', sortDir: 'des' });
      const discountedItemsSearched = await ItemsApi.getDiscountedItems({ size: 4, sortBy: 'discount', sortDir: 'des' });
      setRecentItems(recentItemsSearched.items);
      setDiscountedItems(discountedItemsSearched);
    };

    search();
  }, []);

  return (
    <React.Fragment>
      {props.featureToggle && (
        <React.Fragment>
          <div className="page-container discounted-items">
            <ItemCarousel title="Descuentos:" items={discountedItems}/>
          </div>
          <div className="page-container recent-items">
            <ItemGrid title="Añadidos recientemente:" items={recentItems} />
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default Home;
