import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import Price from '../../Price/Price';
import { Link } from 'react-router-dom';

function ItemCard(props) {
  return (
    <Card className="card-item" sx={{ maxWidth: 350 }}>
      <CardActionArea component={Link} to={'/items/' + props.item.variantId}>
        <CardMedia
          component="img"
          height="432"
          image={ props.item.mainImage }
          alt={ props.item.name }
        />
        <CardContent>
          <Typography gutterBottom variant="subtitle1" component="div" className="typography" sx={{ fontWeight: 'bold' }}>
            { props.item.name }
          </Typography>
          <Price price={props.item.price} discount={props.item.discount} />
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default ItemCard;
