import axios from 'axios';
import HTTP_STATUS_CODE from '../constants/httpStatusCodes';

function handleError(error) {
  const url = error.config.url;
  const allowList = ['/login', '/principal'];
  const allowListReg = new RegExp(`(${allowList.join('|')})`, 'g');

  if (!allowListReg.test(url) && error.response) {
    const status = error.response.status;
    if (status === HTTP_STATUS_CODE.UNAUTHORIZED) {
      window.location = '/login';
    } else if (status === HTTP_STATUS_CODE.FORBIDDEN || status >= HTTP_STATUS_CODE.INTERNAL_SERVER_ERROR) {
      window.location = '/server-error';
    }
  }
  return Promise.reject(error);
}

const axiosInstance = axios.create({
  timeout: 60 * 1000,
  withCredentials: true,
});

axiosInstance.interceptors.response.use((response) => {
  return response;
}, handleError);

export default axiosInstance;
