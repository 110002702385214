import React from 'react';

import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';

function Price(props) {
  const hasDiscount = () => {
    return props.discount || props.discount === '0';
  };
  const getPriceWithDiscount = () => {
    return (props.price * (1 - props.discount/100)).toFixed(2);
  };
  const getPrizeStyle = () => {
    let priceStyle = {};
    if (hasDiscount(props.discount)) {
      priceStyle = { ...priceStyle, ...{ textDecoration: 'line-through' } };
    }

    return { ...priceStyle, ...props.priceCss };
  };

  return (
    <Grid container spacing={1} alignItems="center" justifyContent="flex-start">
      <Grid item key="price">
        <Typography variant="body1" className="typography" sx={getPrizeStyle}>
          { props.price }€
        </Typography>
      </Grid>
      {hasDiscount() && (
        <Grid item key="discount">
          <Typography variant="body1" color="darkred" className="typography" sx={props.discountCss}>
            { getPriceWithDiscount() }€
          </Typography>
        </Grid>)}
    </Grid>
  );
}

export default Price;
