import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { useSelector } from 'react-redux';

import RootPage from './RootPage/RootPage';
import Home from './HomePage/Home';
import ProductsPage from './ProductsPage/ProductsPage';
import SingleItemPage from './SingleItemPage/SingleItemPage';
import LoginPage from './LoginPage/LoginPage';
import NotFound from './NotFound/NotFound';
import SignInPage from './SignInPage/SignInPage';

function App() {
  const {
    homePageFeatureToggle,
    productsPageFeatureToggle,
    singleItemPageFeatureToggle,
    loginPageFeatureToggle,
    signInPageFeatureToggle
  } = useSelector((state) => state.featureToggles);

  const router = createBrowserRouter([
    {
      path: '/',
      element: <RootPage/>,
      children: [
        {
          index: true,
          element: <Home featureToggle={homePageFeatureToggle} />
        },
        {
          path: '/items',
          element: <ProductsPage featureToggle={productsPageFeatureToggle} />
        },
        {
          path: '/items/:itemId',
          element: <SingleItemPage featureToggle={singleItemPageFeatureToggle} />
        },
        {
          path: '/logIn',
          element: <LoginPage featureToggle={loginPageFeatureToggle} />
        },
        ... signInPageFeatureToggle ? [{
          path: '/signIn',
          element: <SignInPage featureToggle={signInPageFeatureToggle} />
        }] : []
      ]
    },
    {
      path: '*',
      element: <NotFound/>
    }
  ]);

  return (
    <div id="body-content">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
