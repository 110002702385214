import axios from '../axiosConfig';
import serverURL from '../base';

const SizesApi = {
  getSizesByItemId: async (id) => {
    const response = await axios.get(`${serverURL}/sizes/${id}`);
    return response.data;
  },
};

export default SizesApi;
