import React, { useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import { ListItemText, ListItemButton, ListItemIcon } from '@mui/material';

function FilterForm({ name, filters, setFilter }) {
  const [state, setState] = useState({});

  const handleChange = (event) => {
    const filter = { ...state, [event.target.name]: event.target.checked };
    setState(filter);
    setFilter(name, filter);
  };

  return (
    <React.Fragment>
      {filters.map((filter, index) => (
        <ListItemButton onClick={handleChange} dense key={`${filter.name}_${filter.id}_${index}`}>
          <ListItemIcon>
            <Checkbox
              edge="start"
              checked={state[filter.name]}
              onChange={handleChange}
              name={filter.name}
              tabIndex={-1}
              disableRipple
            />
          </ListItemIcon>
          <ListItemText primary={filter.name} />
        </ListItemButton>
      ))}
    </React.Fragment>
  );
}

export default FilterForm;
