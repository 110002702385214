import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { authApi } from './api/auth';
import { featureTogglesReducer } from './slices/featureTogglesSlice';
import { signInApi } from './api/signIn';

const rootReducer = combineReducers({
  [authApi.reducerPath]: authApi.reducer,
  [signInApi.reducerPath]: signInApi.reducer,
  featureToggles: featureTogglesReducer
});

export const setupStore = (preloadedState) =>
  configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(authApi.middleware).concat(signInApi.middleware),
  });

const store = setupStore();

setupListeners(store.dispatch);

export { useLoginMutation, useLogoutMutation } from './api/auth';
export { useSignInMutation } from './api/signIn';
export { store };
